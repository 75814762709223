var Filtros = (function() {
	var filtros = $('.filtro');

	filtros.each(function() {
		var filtro	= $(this);
		var input 	= filtro.find('input[type="hidden"]');
		var options = filtro.find('a');
		var output 	= filtro.find('.filter-text');
		var header 	= filtro.find('.header');

		filtro.data('open', false);

		header.click(function(e) {
			e.preventDefault();

			var open = filtro.data('open');

			if(midiaSize == 'sm' || midiaSize == 'xs') {
				filtros.removeClass('show');


				filtros.data('open', false);

				if(open) {
					filtro.removeClass('show');
					filtro.data('open', false);
				}else {
					filtro.addClass('show');
					filtro.data('open', true);

				}

			}
		});

		filtro.mouseenter(function() {
			if(midiaSize == 'xl' || midiaSize == 'lg' || midiaSize == 'md') {
				filtro.addClass('show')
			}
		});

		filtro.mouseleave(function() {
			if(midiaSize == 'xl' || midiaSize == 'lg' || midiaSize == 'md') {
				filtro.removeClass('show')
			}
		});

		options.click(function(e) {
			e.preventDefault();
			var $this =  $(this);

			output.text($this.text());
			output.attr('title',$this.text());
			input.val($this.data('value'));

			options.removeClass('active');
			$this.addClass('active');

			filtro.removeClass('show');
		});
	});

})();
