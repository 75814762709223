var TogglersTopo =  (function () {
	var btns = $('button.btn-topo');
	var topo = $('.topo');
	var html = $('body');

	btns.each(function() {
		var btn 		= $(this);
		var toggle 		= btn.data('toggle');
		var expanded 	= btn.data('expanded');
		var t = $(window).scrollTop();

		if(!expanded) {
			expanded = false;
		}

		btn.click(function(e) {
			e.preventDefault();


			btn.toggleClass('active');

			t = $(window).scrollTop();

			if(!expanded) {

				topo.attr('class', 'topo');
				topo.addClass('show-'+toggle);
				html.addClass('lockscroll');

			} else {
				topo.removeClass('show-'+toggle);
				html.removeClass('lockscroll');
			}


			expanded = !expanded
		});

		btn.removeAttr('data-toggle');
	});

	var buscasContainer = $('.buscas');
	var formBuscas 		= $('.buscas form');

	buscasContainer.click(function(e) {
		if(midiaSize == 'xs' || midiaSize == 'sm') {
			btns.removeClass('active');
			topo.removeClass('show-search');
			html.removeClass('lockscroll');
		}
	});

	formBuscas.click(function(e){
		if(midiaSize == 'xs' || midiaSize == 'sm') {
			e.stopPropagation();
		}
	});
})();
