var MdInput = (function() {
	var mdinputs = $('.md-input');

	mdinputs.each(function() {
		mdInputRegister($(this));
	});

	function mdInputRegister(mdInput){
		var input = mdInput.find('.form-control');

		mdInput.on('md.check', function() {
			if(input.val() != '') {
				mdInput.addClass('focus');
			}else {
				mdInput.removeClass('focus');
			}
		});

		if(input.val() != '') {
			mdInput.addClass('focus');
		}

		input.focus(function() {
			mdInput.addClass('focus');
		});

		input.blur(function() {
			mdInput.trigger('md.check');
		});
	}
})();
