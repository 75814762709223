var Newsletter = (function() {
	var newsletter = $('.newsletter');
	var btn = $('.newsletter-toggler, .newsletter .bck');

	newsletter.data('form-hidden', true);

	newsletter.on('toggleForm', function(e) {
		var form = newsletter.data('form-hidden');
		var timeout;

		if(timeout) {
			clearTimeout(timeout);
		}

		if(form) {
			newsletter.addClass('shown');

			timeout = setTimeout(function() {
				newsletter.addClass('show');
			},100);
		}else {
			newsletter.removeClass('show');

			timeout = setTimeout(function() {
				newsletter.removeClass('shown');
			},100);
		}

		newsletter.data('form-hidden', !form);
	});

	btn.click(function() {
		btn.toggleClass('active');
		newsletter.trigger('toggleForm');
	});
})();
