var Alerta = (function() {
	function Alerta() {
		$('body').append('<div id="alertas"></div>');

		this.container = $('#alertas');
	}

	Alerta.prototype.new = function(data) {
		this.container.append(this.template(data));
	}

	Alerta.prototype.template = function(data) {
		var template = '<div class="alert alert-'+(data.variant || 'default')+'">'+
			'<div class="media">'+
				(hasIcon())+
				'<div class="media-body align-self-center">'+data.message+'</div>'+
				'<button type="button" aria-label="fechar" data-dismiss="alert" class="close">&times;</button>'+
			'</div>'+
		'</div>';

		return template;

		function hasIcon() {
			if(data.icon) {
				return '<div class="icon-area">'+data.icon+'</div>';
			}else {
				return '';
			}
		}
	}

	return new Alerta();
})();
