$('.tab-detalhes').each(function() {
	var parent 		= $(this);
	var navTabs 	= parent.find('.nav-detalhes li');
	var tabs 		= parent.find('.nav-detalhes a');
	var tabItens	= parent.find('.tab-detalhes-item');

	tabs.each(function() {
		var target 		= $($(this).attr('href'));
		var thisParent 	= $(this).parent();

		$(this).click(function(e) {
			e.preventDefault();

			tabItens.removeClass('active');
			target.addClass('active');

			navTabs.removeClass('active');
			thisParent.addClass('active');
		});
	});
});

$('.tab-detalhes iframe').each(function() {
	var w = $(this).width();
	var h = $(this).height();

	$(this).wrapAll('<div class="embed-responsive embed-helper" style="--width:'+w+';--height:'+h+'"></div>')
});
