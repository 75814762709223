var Ambientes = (function() {
	var html 			= $('html, body');
	var scrollPosition 	= 0;

	$('[data-toggle="ambientes"]').each(function(index, el) {
		var target 	= $($(this).attr('href'));
		var btn 	= $(this);

		btn.click(function(e) {
			e.preventDefault();

			scrollPosition = $(window).scrollTop();

			$('#ambientes').carousel(index);

			html.addClass('lockscroll');

			target.fadeIn(600, function() {
				target.addClass('show').removeAttr('style');
			});

		});
	});

	$('[data-dismiss="ambientes"]').each(function() {
		var btn 	= $(this);
		var parent 	= btn.parents('.modal-ambientes');

		btn.click(function(e) {
			e.preventDefault();

			parent.fadeOut(600, function() {
				html.removeClass('lockscroll');

				parent.removeClass('show').removeAttr('style');

				html.animate({
					scrollTop: scrollPosition
				}, 400);
			});

		});
	});

})();
