var BannerSettings = (function() {
	var banner = $('#banner');

	if(banner.length > 0) {
		var items = banner.find('.carousel-item');

		if(items.length > 1) {
			var controles = '<div class="banner-controls">'+
				'<a href="#banner"  data-slide="prev" aria-label="Anterior">'+items.length+'</a>'+
				'<a href="#banner"  data-slide="next" aria-label="Próximo">2</a>'+
			'</div>';

			banner.append(controles);

			var prev = banner.find('[data-slide="prev"]');
			var next = banner.find('[data-slide="next"]');
			var i 	 = items.length;

			banner.on('slide.bs.carousel', function(e) {
				var f = e.from;
				var t = e.to;

				if(t == 0) {
					next.text(2);
					prev.text(i)
				}

				else if (t==(i - 1)) {
					next.text(1)
					prev.text(t);
				}

				else {
					next.text(t+2);
					prev.text(t);
				}
			});
		}
	}
})();
