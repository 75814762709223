var TopoFixo = (function() {
	var topo = $('.topo');
	var topoHeight = 54;
	verify();

	function verify() {

		if($(window).scrollTop() > 0) {
			topo.addClass('topo-fixo');
		}else {
			topo.removeClass('topo-fixo');
		}

		requestAnimationFrame(verify);
	}
})();
