var midiaSize = (function() {
	var windowSize = 0;

	return verifier();

	function verifier() {
		var currentSize = window.innerWidth;

		if(currentSize != windowSize) {
			return verifyMidia();

			windowSize = currentSize;
		}

		requestAnimationFrame(verifier);
	}

	function verifyMidia () {
		var size = '';
		var w = window.innerWidth;

		if(w < 500) {
			size = 'xs';
		}

		if(w >= 500) {
			size = 'sm';
		}

		if(w >= 768) {
			size = 'md';
		}

		if(w >= 992) {
			size = 'lg';
		}

		if(w >= 1200) {
			size = 'xl';
		}

		return size;
	}

})();

const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function basePath () {
	let p = '';

	if($('body').data('path')) {
		p = $('body').data('path') + '/';
	}

	return p;
}
