$('[data-toggle="video-modal"]').each(function() {
	var video = $(this).find('template').html();
	var link  = $(this).find('a');
	var modal = $(link.data('target'));
	var ifr	  = modal.find('.embed-responsive');

	modal.on('hide.bs.modal', function() {
		ifr.html('');
	});

	link.click(function(e) {
		e.preventDefault();

		ifr.html(video);
	});
});
