var AnimeOnViewport = (function() {
	var observer = new IntersectionObserver(function(entries) {
		$(entries).each(function (index, entry) {
			if(entry.isIntersecting) {
				var el = $(entry.target);
				var animation = el.data('animate');

				el.addClass(animation)
					.removeAttr('data-animate');

				observer.unobserve(entry.target);
			}
		});
	}, {
		rootMargin: '-50px -50px -50px -50px'
	});

	$('[data-animate]').each(function() {
		observer.observe(this);
	});
})();
